.container {
    width: 33%;
}
.step_heading {
    background-color: var(--black);
    color: var(--yellow);
    padding: 2% 5%;
    border-radius: 4px;
    font-size: 1.5rem;
}
.content {
    margin-top: 2vh;
    font-weight: 300;
    font-size: 1.2rem;
    text-align: justify;
}
@media (max-width: 1400px) {
    .step_heading {
        font-size: 1.2rem;
    }
    .content {
        font-size: 1rem;
    }
}
@media (max-width: 1400px) {
    .container {
        width: 100%;
    }
}