.backtotop {
    position: fixed;
    background-color: var(--yellow);
    bottom: 50px;
    right: 50px;
    z-index: 1;
    cursor: pointer;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--black);
}
.backtotop img{
    width: 20px;
}
.container {
    padding-top: 12vh;
    padding-left: 10vw;
    padding-right: 10vw;
}
.main_container {
    height: 86vh;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    position: relative;
}
.left_content{
    padding-top: 17vh;
    height: 100%;
    width: 40%;
}
.right_animation  {
    width: 55%;
    height: 85%;
    padding-top: 5vh;
}
.right_animation img{
    width: 100%;
    height: 100%;
    /* border: 1px solid #000; */
    /* border-radius: 10px 10px; */
}
.heading {
    font-weight: normal;
    font-size: 3rem;
    font-weight: 100;
    line-height: 3.5rem;
    letter-spacing: -0.5px;
}
.content {
    margin: 5vh 0;
    font-size: 1.4rem;
    font-weight: 100;
    line-height: 2rem;
    /* font-weight: normal; */
}
.button {
    padding: 4%;
    font-size: 24px;
    font-weight: medium;
    background-color: var(--yellow);
    border-radius: 5px;
    box-shadow: 4px 4px 0px #000000;
    cursor: pointer;
}
.how_it_works {
    text-align: center;
    position: absolute;
    top: 90vh;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid #000;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.form {
    display: flex;
    gap: 20px;
}
.input {
    background-color: transparent;
    cursor: text;
}
.input::placeholder {
    font-weight: 100;
}
.container_coupon {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}
.upper_container {
    background-color: var(--yellow);
    padding: 0 10vw;
    min-height: 80vh;
    position: relative;
}
.second_heading {
    text-align: center;
    padding: 15vh 0;
    font-weight: 100;
    font-size: 3rem;
}
.flex_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 0 10vh 0;
    gap: 100px;
}
.add_button {
    position: absolute;
    padding: 1% 2%;
    font-size: 1.5rem;
    border-radius: 4px;
    border: 2px solid var(--black);
    outline: 5px solid white;
    left: 50%;
    bottom: -5%;
    transform: translateX(-50%);
    background-color: var(--yellow);
    cursor: pointer;
}
.lower_container {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid #000;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.container_stats {
    min-height: 100vh;
}
.upper_container_stats {
    padding: 0 10vw;
    min-height: 60vh;
}
.galary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
}
.brandlogo {
    width: 150px;
}
.lower_container_stats {
    padding: 0 10vw;
    min-height: 40vh;
    background-color: var(--yellow);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.lower_container_stats > * {
    width: 33.33%;
    text-align: center;
    padding: 5vh 5vw;
}
.lower_container_stats > *:nth-child(2){
    border-left: 2px solid var(--black);
    border-right: 2px solid var(--black);
}
.flex_heading {
    font-weight: bold;
    font-size: 2.5rem;
}
.flex_content {
    font-weight: 100;
    font-size: 1.2rem;
}
.heading_review {
    text-align: center;
    padding: 15vh 0;
    font-weight: 100;
    font-size: 3rem;
}
.review_flexbox {
    padding: 0 10vw 25vh 10vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.review_flex {
    background-color: var(--yellow);
    padding: 2%;
    width: 33%;
    height: 35vh;
    border-radius: 4px;
}
.rating {
    margin: 2vh 0;
    display: flex;
    align-items: center;
}
.reviewer {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 2vh 0;
}
.review {
    font-size: 1.2rem;
    font-weight: 100;
}

.lower_container_mobile {
    display: none;
}
.toast {
    background-color: var(--yellow);
    border: 1px solid black;
    border-radius: 50px;
    color: #000000;
    outline: none;
    width: 100%
}
.toastClass {
    box-shadow: none;
    padding: 0;
}

@media (max-width: 1400px) {
    .heading {
        font-size: 2rem;
        line-height: 2.5rem;
    }
    .content {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .button {
        font-size: 1rem;
    }
    .how_it_works {
        font-size: 0.9rem;
    }
    .second_heading,
    .heading_review {
        font-size: 2rem;
    }
    .flex_wrapper {
        gap: 50px;
    }
    .add_button {
        font-size: 1.2rem;
    }
    .lower_container {
        font-size: 0.9rem;
    }
    .brandlogo {
        width: 100px;
    }
    .flex_heading {
        font-size: 2rem;
    }
    .flex_content {
        font-size: 1rem;
    }
    .reviewer {
        font-size: 1.2rem;
    }
    .review {
        font-size: 1rem;
    }
}
@media (max-width: 800px) {
    .backtotop {
        bottom: 12vh;
    }
    .main_container {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
    }
    .container {
        padding-top: 0;
        padding-bottom: 5vh;
    }
    .main_container {
        height: 92vh;
    }
    .how_it_works {
        display: none;
    }
    .heading {
        margin-top: 5vh;
    }
    .form {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 5vh;
    }
    .form > * {
        width: 100%;
    }
    .left_content,.right_animation {
        width: 100%;
    }
    .left_content {
        height: max-content;
        padding-top: 0;
    }
    .right_animation {
        /* margin-top: 5vh; */
        height: 40%;
    }
    .second_heading {
        padding: 12vh 0;
    }
    .flex_wrapper {
        flex-direction: column;
    }
    .add_button {
        bottom: -2.5%;
    }
    .lower_container_stats {
        flex-direction: column;
    }
    .lower_container_stats > *:nth-child(2){
        border-top: 2px solid var(--black);
        border-bottom: 2px solid var(--black);
        border-left: 0;
        border-right: 0;
    }
    .lower_container_stats > * {
        width: 100%;
        padding: 5vh 0;
    }
    .flex_content {
        margin-top: 2vh;
    }
    .review_flexbox {
        flex-direction: column;
    }
    .review_flex {
        width: 100%;
        height: fit-content;
    }
}
@media (max-width: 600px) {
    .heading {
        font-size: 1.6rem;
        line-height: 2rem;
    }
    .content {
        margin: 2.5vh 0;
    }
    .container_coupon {
        height: inherit;
    }
    .upper_container {
        min-height:max-content;
    }
    .add_button {
        bottom: -1.5%;
        font-size: 0.9rem;
    }
    
    .lower_container {
        display: none;
    }
    .lower_container_mobile {
        margin: 10vh 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .lower_container_mobile_link {
        text-decoration: underline;
    }
    .galary {
        margin-bottom: 5vh;
    }
    .review_flex {
        padding: 5%;
    }
}
@media (max-width: 600px) and (max-height: 850px)  {
    .form {
        margin-top: 2.5vh;
    }
    .right_animation {
        height: 35%;
    }
}
@media (max-width: 600px) and (max-height: 700px)  {
    .main_container {
        height: 100vh;
    }
}