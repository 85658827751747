.footer_container {
    padding: 2.5vh 10vw;
    background-color: var(--black);
    color: var(--yellow);
    display: flex;
    justify-content: space-between;
    font-weight: 100;
    /* font-size: 2rem; */
    /* text-align: center; */
}
.footer_left {
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer_right {
    display: flex;
    gap: 20px;
}
.footer_right > * {
    width: 30px;
}

@media (max-width: 800px) {
    .footer_container {
        flex-direction: column-reverse;
    }
    .footer_left,
    .footer_right {
        justify-content: center;
        margin: 2vh 0;
    }
}

@media (max-width: 600px) {
    .footer_right > * {
        width: 25px;
    }
}