@font-face {
  font-family: "general_sans";
  src: url("./assets/fonts/GeneralSans-Regular.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "general_sans";
  src: url("./assets/fonts/GeneralSans-Bold.ttf") format("truetype");
  font-weight: bolder;
}
@font-face {
  font-family: "general_sans";
  src: url("./assets/fonts/GeneralSans-Semibold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "general_sans";
  src: url("./assets/fonts/GeneralSans-Medium.ttf") format("truetype");
  font-weight: medium;
}

:root {
  --yellow: #FFD646;
  --black: #000;
}


*, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "general_sans", sans-serif;
}
*::-webkit-scrollbar {
  width: 0;
}