.goback {
    position: absolute;
    top:10vh;
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1.2rem;
    cursor: pointer;
    border: 2px solid #000;
    border-radius: 50%;
    padding: 1.2%;
}
@media (max-width: 700px) {
    .goback {
        padding: 5%;
    }
}