.navbar_wrapper {
    position: relative;
    padding: 0 10vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: rgba(1, 1, 1, 0.001);
    backdrop-filter: blur(20px);
    transition: all 0.3s ease;
}
.mobile_navlinks,
.expanded_menu {
    display: none;
}
.logo {
    width: 200px;
}
.navlinks {
    display: flex;
}
.navlink {
    margin-left: 2.5vw;
    font-size: 1.2rem;
    font-weight: medium;
    text-decoration: none;
    color: black;
}
.navlink:first-child {
    margin-left: 0;
}
@media (max-width:1400px) {
    .navlink {
        font-size: 1rem;
    }
    .logo {
        width: 150px;
    }
}
@media (max-width: 800px) {
    .mobile_navlinks {
        display: block;
    }
    .navlinks {
        display: none;
    }
    .close_icon {
        width: 30px;
        margin: 10vh 0;
    }
    .expanded_menu {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        position: fixed;   
        z-index: 1;
        height: 100%;
        width: 100%;
        background-color: var(--yellow);
        transition: all 0.3s ease-out;
    }
    .expanded_menu > .navlink {
        margin: 2.5vh 0;
    }
}